import { FoodItem, MenuItems } from 'types';

export const mealsForOne: MenuItems = [
  {
    number: 1,
    name: 'Chicken Meal',
    price: 6.5,
    subItems: [
      {
        quantity: 2,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 2,
    name: 'Combo Meal 1',
    price: 6.5,
    subItems: [
      {
        quantity: 1,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 3,
    name: 'Chicken Mega',
    price: 7.5,
    subItems: [
      {
        quantity: 3,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 4,
    name: 'Combo Meal 2',
    price: 7.5,
    subItems: [
      {
        quantity: 2,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 5,
    name: 'Spicy Wing',
    price: 6.5,
    subItems: [
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 6,
    name: 'Mini Chicken Sampler',
    price: 8.5,
    subItems: [
      {
        quantity: 1,
        unit: 'piece',
        item: 'Chicken',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        quantity: 1,
        item: 'Fillet Strip',
      },
      {
        quantity: 6,
        unit: 'piece',
        item: 'Popcorn',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 7,
    name: 'Nuggets Meal',
    price: 6.5,
    subItems: [
      {
        quantity: 6,
        unit: 'piece',
        item: 'Nuggets',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 8,
    name: 'Popcorn Meal',
    price: 6.5,
    subItems: [
      {
        quantity: 12,
        unit: 'piece',
        item: 'Popcorn',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 9,
    name: 'Chicken Strips',
    price: 7.5,
    subItems: [
      {
        quantity: 5,
        unit: 'piece',
        item: 'Strips',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 10,
    name: 'BBQ Ribs & Chicken Meal',
    meals: [
      {
        price: 8,
        subItems: [
          {
            item: 'Lamb Ribs',
            quantity: 2,
          },
          {
            quantity: 1,
            unit: 'piece',
            item: 'Chicken',
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
      {
        price: 9,
        subItems: [
          {
            item: 'Lamb Ribs',
            quantity: 3,
          },
          {
            quantity: 1,
            unit: 'piece',
            item: 'Chicken',
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
      {
        price: 8,
        subItems: [
          {
            item: 'Lamb Rib',
            quantity: 1,
          },
          {
            quantity: 1,
            unit: 'piece',
            item: 'Chicken',
          },
          {
            quantity: 3,
            item: 'Spicy Wings',
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    number: 11,
    name: 'BBQ Golden Meal',
    price: 7,
    subItems: [
      {
        quantity: 2,
        item: 'BBQ Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 12,
    name: 'BBQ Wings',
    price: 7,
    subItems: [
      {
        quantity: 6,
        item: 'BBQ Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 13,
    name: 'BBQ Wings & BBQ Chicken',
    price: 7,
    subItems: [
      {
        quantity: 3,
        item: 'BBQ Wings',
      },
      {
        quantity: 1,
        item: 'BBQ Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  // {
  //   number: 14,
  //   name: 'BBQ Ribs',
  //   meals: [
  //     {
  //       price: 6.5,
  //       subItems: [
  //         {
  //           item: 'Lamb Ribs',
  //           quantity: 4,
  //         },
  //         {
  //           item: 'Fries',
  //           size: 'Regular',
  //         },
  //         {
  //           quantity: 1,
  //           unit: 'can',
  //           item: 'drink',
  //         },
  //       ],
  //     },
  //     {
  //       price: 7.5,
  //       subItems: [
  //         {
  //           item: 'Lamb Ribs',
  //           quantity: 6,
  //         },
  //         {
  //           item: 'Fries',
  //           size: 'Regular',
  //         },
  //         {
  //           quantity: 1,
  //           unit: 'can',
  //           item: 'drink',
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    number: 15,
    name: 'BBQ Burger',
    price: 7,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 16,
    name: 'Chicken Burger',
    price: 6.5,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 17,
    name: 'Chicken Burger & Wings',
    price: 8,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 18,
    name: 'Tower Burger',
    price: 7.5,
    subItems: [
      {
        item: 'Chicken Fillet Burger with Hash Brown & Cheese',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 19,
    name: 'Ringer Burger',
    price: 7.5,
    subItems: [
      {
        item: 'Chicken Fillet Burger with 2 Onion Rings & Cheese',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 20,
    name: 'Fish Burger',
    price: 6.5,
    subItems: [
      {
        item: 'Fish Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 21,
    name: 'Veggie Burger',
    price: 6.5,
    subItems: [
      {
        item: 'Veggie Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 22,
    name: 'Burger+ Meal',
    price: 9.5,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        quantity: 1,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        item: 'Coleslaw or Beans',
        size: 'Regular',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 23,
    name: 'Beef Burger',
    price: 7,
    subItems: [
      {
        item: '1/4 Pounder',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 24,
    name: '1/2 Pounder',
    price: 8.5,
    subItems: [
      {
        item: '1/2 Pounder',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 25,
    name: 'Double Decker',
    price: 6.5,
    subItems: [
      {
        item: 'Double Cheese Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 26,
    name: 'Chicken Wrap',
    price: 6.5,
    subItems: [
      {
        item: 'Chicken Wrap',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 27,
    name: 'BBQ Wrap',
    price: 7,
    subItems: [
      {
        item: 'BBQ Chicken Wrap',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 28,
    name: 'Veggie Wrap',
    price: 6.5,
    subItems: [
      {
        item: 'Veggie Wrap',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 29,
    name: 'Yummy\'s Bucket',
    price: 27,
    subItems: [
      {
        quantity: 4,
        unit: 'piece',
        item: 'Chicken',
      },
      {
        quantity: 2,
        item: 'Fillet Burgers',
      },
      {
        quantity: 2,
        item: 'Cheese Burgers',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 30,
    name: 'Mixed Meal',
    price: 15,
    subItems: [
      {
        quantity: 3,
        unit: 'piece',
        item: 'Chicken',
      },
      {
        quantity: 1,
        item: 'Fillet Burger',
      },
      {
        quantity: 4,
        unit: 'piece',
        item: 'Spicy Wings',
      },
      {
        quantity: 2,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 2,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 31,
    name: 'Boneless Meal',
    price: 8,
    subItems: [
      {
        quantity: 3,
        item: 'Fillet Strips',
      },
      {
        size: 'Small',
        item: 'Poppers',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 32,
    name: 'Sausage Meal',
    price: 6.5,
    subItems: [
      {
        item: 'Sausage',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 33,
    name: 'Cod Fish Meal',
    price: 8.5,
    subItems: [
      {
        item: 'Cod Fish',
        size: 'Large',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 34,
    name: 'Scampi Meal',
    price: 8,
    subItems: [
      {
        item: 'Scampi',
        unit: 'piece',
        quantity: 8,
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
];

export const mealsForOnePlus: MenuItems = [
  {
    number: 35,
    name: 'Family Deal',
    price: 30,
    subItems: [
      {
        quantity: 6,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 3,
        item: 'Chicken Fillet Burgers',
      },
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    number: 36,
    name: 'Boneless Deal',
    price: 25,
    subItems: [
      {
        quantity: 6,
        item: 'Fillet Strips',
      },
      {
        quantity: 2,
        item: 'Chicken Fillet Burgers',
      },
      {
        quantity: 2,
        item: 'Chicken Wraps',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    number: 37,
    name: 'Boneless Sampler',
    price: 25,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken Fillets',
        unit: 'piece',
      },
      {
        quantity: 8,
        item: 'Fillet Strips',
      },
      {
        size: 'Large',
        item: 'Poppers',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    number: 38,
    name: 'Family Value Meal',
    meals: [
      {
        price: 19,
        subItems: [
          {
            quantity: 6,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 6,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 21,
        subItems: [
          {
            quantity: 8,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 27,
        subItems: [
          {
            quantity: 12,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    number: 39,
    name: 'Chicken Sampler',
    price: 25,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 4,
        item: 'Fillet Strips',
      },
      {
        quantity: 8,
        item: 'Spicy Wings',
      },
      {
        size: 'Large',
        item: 'Poppers',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    number: 40,
    name: 'BBQ Bucket',
    price: 20,
    subItems: [
      {
        quantity: 4,
        item: 'BBQ Chicken',
        unit: 'piece',
      },
      {
        quantity: 1,
        item: 'BBQ Chicken Burger',
      },
      {
        quantity: 8,
        item: 'BBQ Wings',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    number: 41,
    name: 'Wings Bucket',
    meals: [
      {
        price: 19,
        subItems: [
          {
            quantity: 20,
            item: 'Spicy Wings',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 21,
        subItems: [
          {
            quantity: 30,
            item: 'Spicy Wings',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    name: 'Yummy Special',
    meals: [
      {
        price: 25,
        subItems: [
          {
            quantity: 10,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 1,
            item: 'Salad',
            unit: 'box',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 27,
        subItems: [
          {
            quantity: 12,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 1,
            item: 'Salad',
            unit: 'box',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    number: 42,
    name: 'Family Round House Sampler',
    price: 33,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 2,
        item: 'Chicken Fillet Burgers',
      },
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        item: 'Lamb Ribs',
        quantity: 4,
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
];

export const friedChicken: FoodItem[] = [
  {
    name: 'Fried Chicken',
    variants: [
      {
        price: 2.5,
        variant: '1 piece',
      },
      {
        price: 4.5,
        variant: '2 pieces',
      },
      {
        price: 6,
        variant: '3 pieces',
      },
    ],
  },
  {
    name: 'Fillet Strips',
    variants: [
      {
        price: 5,
        variant: '4 pieces',
      },
      {
        price: 7,
        variant: '6 pieces',
      },
    ],
  },
  {
    name: 'BBQ Chicken',
    variants: [
      {
        price: 2.8,
        variant: '1 piece',
      },
      {
        price: 5,
        variant: '2 pieces',
      },
      {
        price: 6.5,
        variant: '3 pieces',
      },
    ],
  },
  {
    name: 'BBQ Wings',
    variants: [
      {
        price: 3.5,
        variant: '4 pieces',
      },
      {
        price: 5,
        variant: '6 pieces',
      },
      {
        price: 7,
        variant: '10 pieces',
      },
    ],
  },
  {
    name: 'Spicy Wings',
    variants: [
      {
        price: 2.5,
        variant: '3 pieces',
      },
      {
        price: 4.3,
        variant: '6 pieces',
      },
      {
        price: 6.5,
        variant: '10 pieces',
      },
    ],
  },
  {
    name: 'Nuggets',
    variants: [
      {
        price: 3,
        variant: '4 pieces',
      },
      {
        price: 4,
        variant: '6 pieces',
      },
      {
        price: 6.5,
        variant: '10 pieces',
      },
    ],
  },
  {
    name: 'Poppers',
    variants: [
      {
        price: 4.5,
        variant: 'Small (12 pieces)',
      },
      {
        price: 5.5,
        variant: 'Large (20 pieces)',
      },
    ],
  },
];

export const periPeriChicken: FoodItem[] = [
  {
    name: '5 Peri Peri Chicken Strips',
    variants: [
      {
        price: 5.5,
        variant: 'On its own',
      },
      {
        price: 8,
        variant: 'Meal',
      },
    ],
  },
  {
    name: '5 Peri Peri Fillet Burger',
    variants: [
      {
        price: 5.5,
        variant: 'On its own',
      },
      {
        price: 8,
        variant: 'Meal',
      },
    ],
  },
  {
    name: 'Peri Peri Chicken Wrap',
    variants: [
      {
        price: 5.5,
        variant: 'On its own',
      },
      {
        price: 8,
        variant: 'Meal',
      },
    ],
  },
];

export const startersAndExtras: FoodItem[] = [
  {
    name: 'Coleslaw',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'BBQ Beans',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'Fries',
    variants: [
      {
        price: 3,
        variant: 'Regular',
      },
      {
        price: 4,
        variant: 'Large',
      },
    ],
  },
  {
    name: 'Fries with Cheese',
    variants: [
      {
        price: 4,
        variant: 'Medium',
      },
      {
        price: 5,
        variant: 'Large',
      },
    ],
  },
  {
    name: 'Sausage',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'Onion Rings',
    variants: [
      {
        price: 3.5,
        variant: '8 pieces',
      },
      {
        price: 4.5,
        variant: '12 pieces',
      },
    ],
  },
  {
    name: 'Potato Wedges',
    variants: [
      {
        price: 3.5,
        variant: 'Small',
      },
      {
        price: 4.5,
        variant: 'Large',
      },
    ],
  },
  {
    name: 'Corn on the Cob',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'Hash Brown',
    variants: [
      {
        price: 3,
        variant: '3 pieces',
      },
    ],
  },
  {
    name: 'Apple Pie',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'Chilli Cheese Poppers',
    variants: [
      {
        price: 4,
        variant: '6 pieces',
      },
      {
        price: 7,
        variant: '12 pieces',
      },
    ],
  },
  {
    name: 'Mozzarella Sticks',
    variants: [
      {
        price: 4.5,
        variant: '4 pieces',
      },
      {
        price: 7.5,
        variant: '8 pieces',
      },
    ],
  },
  {
    name: 'Sauces',
    description: 'Chilli Sauce, Garlic Sauce, Burger Sauce, Ketchup or Mayo',
    variants: [
      {
        price: 1,
      },
    ],
  },
  {
    name: 'Slice of Cheese',
    variants: [
      {
        price: 0.5,
      },
    ],
  },
];

export const drinks: FoodItem[] = [
  {
    name: 'Water',
    description: '0.5l bottle',
    variants: [
      {
        price: 1,
      },
    ],
  },
  {
    name: 'Cans',
    description: 'Coke, Diet Coke, Tango Orange, Pepsi, 7UP or Dr. Pepper',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Bottle',
    description: 'Coke, Diet Coke, Tange Orange, Pepsi, 7UP or Dr. Pepper',
    variants: [
      {
        price: 3.5,
      },
    ],
  },
];

export const fish: FoodItem[] = [
  {
    name: 'Cod Fish',
    variants: [{ price: 5.5 }],
  },
  {
    name: 'Scampi',
    variants: [
      {
        variant: '8 pieces',
        price: 6,
      },
      {
        variant: '12 pieces',
        price: 7.5,
      },
    ],
  },
  {
    name: 'Scampi & Chips',
    variants: [
      {
        variant: '8 pieces',
        price: 7.5,
      },
    ],
  },
  {
    name: 'Fish & Chips',
    variants: [
      {
        price: 7.5,
      },
    ],
  },
];

export const burgersAndWraps: FoodItem[] = [
  {
    name: 'Chicken Fillet Burger',
    variants: [
      {
        price: 4.5,
      },
    ],
  },
  {
    name: 'Chicken Steak Burger',
    variants: [
      {
        price: 4,
      },
    ],
  },
  {
    name: 'Tower Burger',
    variants: [
      {
        price: 5,
      },
    ],
  },
  {
    name: 'Double Decker Beef Burger',
    variants: [
      {
        price: 4.5,
      },
    ],
  },
  {
    name: 'Double Ringer Burger',
    variants: [
      {
        price: 5,
      },
    ],
  },
  {
    name: 'BBQ Chicken Burger',
    variants: [
      {
        price: 5,
      },
    ],
  },
  {
    name: 'Peri Peri Grilled Fillet Burger',
    variants: [
      {
        price: 6,
      },
    ],
  },
  {
    name: '1/2 Pounder Burger with cheese',
    variants: [
      {
        price: 5.5,
      },
    ],
  },
  {
    name: 'Veggie Burger',
    variants: [
      {
        price: 4.5,
      },
    ],
  },
  {
    name: '1/4 Pounder Burger with cheese',
    variants: [
      {
        price: 5,
      },
    ],
  },
  {
    name: 'Fish Burger',
    variants: [
      {
        price: 4,
      },
    ],
  },
  {
    name: 'Chicken Wrap',
    variants: [
      {
        price: 4.5,
      },
    ],
  },
  {
    name: 'Cheese Burger',
    variants: [
      {
        price: 3.5,
      },
    ],
  },
  {
    name: 'BBQ Chicken Wrap',
    variants: [
      {
        price: 5,
      },
    ],
  },
];

export const kidsMenu: MenuItems = [
  {
    name: '1',
    price: 5,
    subItems: [
      {
        quantity: 1,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 1,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        // unit: 'bottle',
        item: 'Kids drink',
      },
    ],
  },
  {
    name: '2',
    price: 5,
    subItems: [
      {
        quantity: 4,
        item: 'Nuggets',
      },
      {
        quantity: 1,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        // unit: 'bottle',
        item: 'Kids drink',
      },
    ],
  },
  {
    name: '3',
    price: 5,
    subItems: [
      {
        quantity: 6,
        unit: 'piece',
        item: 'Poppers',
      },
      {
        quantity: 1,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        // unit: 'bottle',
        item: 'Kids drink',
      },
    ],
  },
  {
    name: '4',
    price: 5,
    subItems: [
      {
        quantity: 4,
        item: 'Spicy Wings',
      },
      {
        quantity: 1,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        // unit: 'bottle',
        item: 'Kids drink',
      },
    ],
  },
  {
    name: '5',
    price: 5,
    subItems: [
      {
        quantity: 1,
        item: 'Cheese Burger',
      },
      {
        quantity: 1,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        // unit: 'bottle',
        item: 'Kids drink',
      },
    ],
  },
];
