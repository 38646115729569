import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { ReactComponent as MapPinIcon } from 'feather-icons/dist/icons/map-pin.svg';
import { ReactComponent as PhoneIcon } from 'feather-icons/dist/icons/phone.svg';
import Yummys from '../../images/background/yummys.jpeg';

import logo from '../../images/yummys.png';
import { menuSections } from 'helpers/menuSections';

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover pt-8`}
  background-image: url("${Yummys}");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const VerticalSpacer = tw.div`mt-8 w-full`;
const InfoSpacer = tw.div`mt-1 w-full`;

const PrimaryAction = tw.a`rounded-full px-8 py-3 mt-4 text-sm sm:text-base sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline flex items-center justify-between`;

const LinkImage = tw.img`h-8 inline float-left`;

const LinkContainer = tw.div`grid sm:grid-cols-2 sm:gap-4 lg:grid-cols-3 lg:gap-4`;

const HeaderImage = tw.img`h-64 pt-2`;

const HeaderInfo = tw.span`text-base`;

const ContactInfo = styled.div`
  ${tw`text-2xl text-center font-black text-gray-100 leading-snug mt-8`}

  span {
    display: inline-block;
  }
`;

const ActionButton = tw.a`rounded-full px-3 py-2 pr-4 text-sm bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline inline-block ml-3 inline-flex items-center mt-3`;
const MapPin = tw(MapPinIcon)`inline mr-2`;
const Phone = tw(PhoneIcon)`inline mr-2`;

// TODO: VerticalSpacer - refactor
export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <VerticalSpacer />
          <HeaderImage src={logo} alt={`Yummy's logo`} />
          <ContactInfo>
            <span>4 Cambridge Parade,&nbsp;</span>
            <span>Great Cambridge Road,&nbsp;</span>
            <span>Enfield,&nbsp;</span>
            <span>EN1 4JU</span>
            <ActionButton href="http://maps.apple.com/?ll=51.660493,-0.057973&q=Yummy%27s&address=4%20Cambridge%20Parade,%20EN1%204JU">
              <MapPin />
              View on Map
            </ActionButton>
          </ContactInfo>

          <ContactInfo>
            020 8363 8088
            <ActionButton href="tel:+442083638088">
              <Phone />
              Call
            </ActionButton>
          </ContactInfo>
          <VerticalSpacer />
          <ContactInfo>
            <span>Open every day between 11AM - 11PM</span>
            <InfoSpacer />
            <span>Delivery between&nbsp;</span>
            <span>12PM - 10PM&nbsp;</span>
            <HeaderInfo>(except 3PM-4PM on School Days)</HeaderInfo>{' '}
          </ContactInfo>

          {/* <ContactInfo>Open every day between 11AM - 11PM</ContactInfo>

          <ContactInfo>
            <span>Delivery between&nbsp;</span>
            <span>12PM - 10PM&nbsp;</span>
            <HeaderInfo>(except 3PM-4PM on School Days)</HeaderInfo>{' '}
          </ContactInfo> */}

          <ContactInfo>
            <HeaderInfo>Free delivery within 3 miles.</HeaderInfo>{' '}
            <HeaderInfo>Minimum order £15.</HeaderInfo>
          </ContactInfo>

          <VerticalSpacer />
          <LinkContainer>
            {menuSections.map((menuSection, i) => (
              <PrimaryAction key={i} href={`#${menuSection.containerId}`}>
                <LinkImage
                  src={menuSection.icon}
                  alt={`${menuSection.heading} icon`}
                />
                {menuSection.heading}
              </PrimaryAction>
            ))}
          </LinkContainer>
          <VerticalSpacer />
        </Content>
      </HeroContainer>
    </Container>
  );
};

/* TODO:
<div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
*/
